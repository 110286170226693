import styled from 'styled-components';

interface StyledProps {
  $increaseHeight?: boolean;
}

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fafafa;

  .mobile-button--gofoward {
    border-radius: 8px;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 16px;
    box-shadow: 0px -1px 9px 0px rgba(0, 0, 0, 0.21);
    margin: 1rem 0;
  }

  .mobile-button--gofoward-inverted-color {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 1rem 0;
    border-radius: 8px;
    font-size: 16px;
    line-height: normal;
  }

  .waiting-step-footer {
    font-size: 12px;
    color: #8d8d8d;
    font-weight: 400px;
    margin-top: 20px;
    margin-bottom: 25px;
  }
`;

export const StyledInnerContainer = styled.div`
  width: 90%;
  padding-top: 24px;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  flex: 1;
  align-content: center;
`;

export const PageTitleSection = styled.div`
  width: 90%;

  p {
    font-size: 24px;
    font-weight: 700;
    color: #393939;
    margin-bottom: 5px;
  }
  span {
    font-size: 16px;
    font-weight: 400;
    color: #393939;
    margin-bottom: 5px;
  }
`;

export const RedSpan = styled.div`
  color: #ca3232;
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.07px;
  text-align: left;
`;

export const StyledCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 24px;
  padding: 1rem 0;
`;

export const StyledCardShowContractContainer = styled.div`
  width: 100%;
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;
`;

export const FormStyled = styled.div`
  padding: 1rem 0;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  div {
    width: 100%;
  }
`;

export const ContractList = styled.ul`
  padding-top: 1rem;
  padding-bottom: 1rem;
  li {
    font-size: 14px;
    color: #393939;
    padding-top: 8px;
  }
`;

export const StyledButton = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  .button-mobile {
    border-radius: 8px;
    width: 48%;
    display: flex;
    justify-content: center;
    font-size: 16px;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  img {
    width: 100px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  label {
    font-size: 12px;
    font-weight: 600;
    color: #393939;
    margin: 5px 0;
  }

  label > div {
    min-width: 100%;
  }
`;

export const Input = styled.input`
  background-color: #f4f4f4;
  padding: 12px 16px;
  border-radius: 8px;
  border: none;
`;

export const DocumentContainer = styled.div<StyledProps>`
  margin-top: 1rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: ${({ $increaseHeight }) => ($increaseHeight ? 1 / 1.4 : 3 / 2)}; // limita a altura da foto

  .document-frame {
    width: 80vw;
    aspect-ratio: ${({ $increaseHeight }) => ($increaseHeight ? 1 / 1.4 : 3 / 2)}; // limita a altura da borda vermelha
    border: 3px solid red;
    position: absolute;
  }
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  column-gap: 8px;
  width: 100%;
`;

export const CaptureButtonsContainer = styled.div`
  background-color: black;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
`;

export const StyledPhotoPreview = styled.img`
  width: 100%;
  border-radius: 8px;
  padding: 0 !important;
  margin-top: 24px;
`;

export const LivenessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--gray-light-3, #e5e7e7);
  background: var(--gray-light-5, #fafafa);
  height: 500px;
`;

export const CircleButton = styled.div`
  height: 25px;
  width: 25px;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;

  padding: 20px;
  border: 2px solid black;
  outline: 2px solid white;

  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 16px;
  cursor: pointer;
`;

export const ArrowsImg = styled.img`
  height: 25px;
  width: 25px;
  background-color: #fff;
  border-radius: 50%;
  padding: 1px;
  margin-left: 15px;
`;

export const StyledContainerInputRadio = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin: 24px 0 30px 0;
`;

export const StyledModalTitle = styled.div`
  font-family: Arial;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  margin-top: 6px;
  margin-bottom: 24px;
  margin-left: 20px;
  margin-right: 20px;
`;

export const StyledUl = styled.ul`
  li {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 8px;
  }
`;

export const StyledBoxCameraContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 2;

  #box--loading {
    display: none !important;
  }

  #close_camera_container {
    display: none !important;
  }
`;

export const CentralizedContainer = styled.div`
  text-align: center;
  margin: auto;
`;
