import { ButtonHTMLAttributes } from 'react';
import ReactLoading from 'react-loading';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { StyledButton, StyledButtonIcon } from './styles';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  height?: number;
  width?: string;
  maxWidth?: string;
  isInvertColor?: boolean;
  iconSrc?: string;
  icon?: JSX.Element;
  freeContent?: boolean;
  justifyContent?: 'center' | 'space-between';
  loading?: boolean;
  handleRedirection?: { location: string; state?: object };
  className?: string;
  margin?: string;
  children?: any;
  fontSize?: string;
  disabled?: boolean;
}

const CustomButton = ({
  height,
  width,
  maxWidth,
  isInvertColor,
  iconSrc,
  icon,
  freeContent,
  loading,
  handleRedirection,
  className,
  fontSize,
  ...rest
}: IProps) => {
  const theme = useTheme();
  const history = useHistory();

  return (
    <StyledButton
      className={className}
      $disabled={rest.disabled}
      disabled={loading || rest.disabled}
      $isInvertColor={isInvertColor}
      $height={height}
      $width={width}
      $maxWidth={maxWidth}
      $freeContent={freeContent}
      $fontSize={fontSize}
      onClick={() => {
        if (handleRedirection != null) {
          history.push(handleRedirection.location, handleRedirection.state);
        }
      }}
      {...rest}
    >
      {iconSrc && <img src={iconSrc} />}
      {icon && <StyledButtonIcon>{icon}</StyledButtonIcon>}
      {loading ? <ReactLoading type="spinningBubbles" color={isInvertColor ? theme.color.primaryColor : 'white'} /> : rest.children}
    </StyledButton>
  );
};

export default CustomButton;
