import styled from 'styled-components';

interface FilterItemProps {
  $isActive?: boolean;
}

export const Container = styled.div``;

export const PopupTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 20px;
  color: #333333;
  margin: 24px 0px;
`;
export const PopupText = styled.span`
  margin-bottom: 10px;
  width: fit-content;
`;

export const RadiusOptionWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const RadiusLabel = styled.label<FilterItemProps>`
  display: flex;
  align-items: center;
  margin-right: 15px;
  width: 100%;
  height: Fixed (71px) px;
  padding: 16px;
  gap: 8px;
  border-radius: 8px;
  border: 1px;
  opacity: 0px;
  border: ${({ theme, $isActive }) => ($isActive ? `1px solid ${theme.color.primaryColor}` : `1px solid ${theme.color.gray18}`)};
`;

export const RadiusInput = styled.input`
  margin-right: 5px;
  width: 24px;
  height: 24px;
  gap: 0px;
  opacity: 0px;
`;

export const SpanTitle = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  width: 100%;
  text-align: start;
`;

export const SpanSubTitle = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #393939;
  width: 100%;
  text-align: start;
`;

export const LabelComponent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FileContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 104px;
  border: 1px dashed #cccccc;
  border-radius: 8px;
  background-color: #fff;
  padding: 16px;
`;

export const Upload = styled.label`
  width: 48px;
  height: 48px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

export const DragAndDrop = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 12px;
  gap: 16px;
  border-radius: 8px;
  background-color: #eff6ff;
`;

export const ContainerDataDocument = styled.div`
  height: 18px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: transparent;
  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
  img {
    width: 12px;
    height: 12px;
  }
  a,
  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    text-decoration: underline !important;
    color: #010101;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const ContainerTitleAndData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-width: 380px;

  img {
    width: 24px;
  }
  .title {
    font-style: satoshi;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    width: 100%;
    text-align: left;
  }
  .subtitle {
    font-family: Satoshi;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: #999999;
  }
`;
