import CustomButton from 'components/custom-button';
import Header from 'components/general-components/header';
import { HeaderVariants } from 'model/enums/header-variants';
import { RegistrationStatus } from 'model/enums/registration-status';
import SystemStepCategory from 'model/enums/system-step-category';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import clientService from 'services/client-service';
import StringUtils from 'shared/util/string-utils';
import { useTheme } from 'styled-components';
import { Status } from '../dashboard/components/status';
import { TabGeneralData } from './components/tabGeneralData';
import {
  ButtonsContainer,
  CancelButton,
  ContentContainer,
  EmptyContainer,
  InfoButton,
  InfosContainer,
  LivenessContainer,
  PageContainer,
  Tab,
  TabBox,
  TabContainer,
  TabContent,
  TabHeaderInnerContainer,
  TabTitleHeader,
  Title,
  TitleHeader,
  StyleTextModalContainer,
  NoDataStyled,
} from './styles';
import { StepBarSignatureProgress } from '../stepBarSignatureProgress';
import { SignatureHistory, Signatures } from 'model/signatures';
import {
  AttachmentBox,
  CampContainer,
  CampName,
  ModalContainer,
  SelfieDisplayAssign,
  StyledExpandIcon,
} from '../clientData/components/RegisterDataDisplay/campDisplay/styles';
import { Attachment } from 'model/attachment';
import { TabOCR } from './components/tabOCR';
import { TabFaceMatch } from './components/tabFaceMatch';
import { GeneralStatus } from 'model/enums/general-status';
import { PersonSignatureStatusTypes } from 'model/enums/person-signature-status';
import { ReactComponent as ClipIconSvg } from '../../../images/clip.svg';
import CanRetryModal from 'components/canRetry-modal';
import MessageModal from 'components/message-modal';
import { Authority } from 'model/enums/authority';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { hasAuthorities } from 'shared/util/utils';
import CustomModalTwoButtons from 'components/custom-modal-two-buttons';
import { ReactComponent as RedExclamationCircleSvg } from 'images/red-exclamation-circle.svg';
import { ReactComponent as XCircleSvg } from 'images/x-circle.svg';
import organizationService from 'services/organization-service';
import { Organization } from 'model/organization';
import { NewTableList, TableValues } from '../dashboard/components/table-list-new';
import { formatDateTime } from 'shared/util/date-utils';
import { TabDocument } from './components/tabDocument';
import { TabUnico } from './components/tabUnico';
import { UnicoProcessStatus } from 'model/enums/unico-process-status';
import ReplaceDocModal from 'components/replaceDoc-modal';

enum ErrorEnableRetry {
  OCR = 'ocr',
  MATCH = 'match',
  LIVENESS = 'liveness',
}

interface TypeDataExceededAttemptsModal {
  errorType: string;
  numberAttempts: number;
}

interface StepStatus {
  stepName: SystemStepCategory | string;
  order: number;
  status: string | null;
}

export interface TabSelection {
  tabNumber: number;
  tabStep?: SystemStepCategory;
}

export const SignaturesScreen = () => {
  const { color } = useTheme();
  const history = useHistory();
  const location = useLocation<{ prevPath: string }>();

  const errorsBiometricArray = [500, 502, 503, 504, 505, 507, 508, 511, 512];

  const { t } = useTranslation();
  const [tabSelected, setTabSelected] = useState<TabSelection>({ tabNumber: 1 });
  const { id } = useParams<{ id: string }>();
  const [expandModal, setExpandModal] = useState<boolean>(false);
  const [isShowingReplaceDocModal, setIsShowingReplaceDocModal] = useState<boolean>(false);
  const [isShowingCanRetryModal, setIsShowingCanRetryModal] = useState<boolean>(false);
  const [isShowExceededAttemptsModal, setIsShowExceededAttemptsModal] = useState<boolean>(false);
  const [dataExceededAttemptsModal, setDataExceededAttemptsModal] = useState<TypeDataExceededAttemptsModal>();
  const [isShowingMessageModal, setIsShowingMessageModal] = useState<boolean>(false);
  const [signatureData, setSignatureData] = useState<Signatures>();
  const [stepStatus, setStepStatus] = useState<StepStatus[]>([]);
  const [loadingNewRetry, setloadingNewRetry] = useState<boolean>(false);
  const [loadingApprove, setLoadingApprove] = useState<boolean>(false);
  const [loadingCancelContract, setloadingCancelContract] = useState<boolean>(false);
  const [emailButtonLabel, setEmailButtonLabel] = useState<string>(t('admin.signatures.reesendEmail'));
  const account = useSelector((state: IRootState) => state.authentication.account);
  const [organizationData, setOrganizationData] = useState<Organization>();

  const [isLoadingSignatureHistoryData, setIsLoadingSignatureHistoryData] = useState<boolean>(true);
  const [signatureHistoryData, setSignatureHistoryData] = useState<SignatureHistory[]>([]);

  useEffect(() => {
    if (id != null) {
      getSignatureData(Number(id));
      getOrganizationData();
      getSignatureHistory();
    }
  }, []);

  const signatureHistoryTableValues: TableValues[] = [
    {
      header: t('admin.signatures.tabHistoric.table.header.dateAndTime'),
      values: signatureHistoryData.map(historic =>
        t('admin.signatures.tabHistoric.table.value.date', { date: formatDateTime(historic.date) })
      ),
    },
    {
      header: t('admin.signatures.tabHistoric.table.header.typeAction'),
      values: signatureHistoryData.map(historic => t(`admin.signatures.tabHistoric.table.typeActions.${historic.type}`)),
    },
    {
      header: t('admin.signatures.tabHistoric.table.header.comment'),
      values: signatureHistoryData.map(historic => historic.comment),
      fullLength: true,
    },
    {
      header: t('admin.signatures.tabHistoric.table.header.status'),
      values: signatureHistoryData.map(historic => {
        return (
          <Status
            text={
              historic.isSuccessful
                ? t('admin.signatures.tabHistoric.table.statusType.concluded')
                : t(`admin.signatures.tabHistoric.table.statusType.${historic.type}`)
            }
            color={historic.isSuccessful ? GeneralStatus.APPROVED : GeneralStatus.REJECTED}
          />
        );
      }),
    },
  ];

  const getTabContent = () => {
    if (signatureData?.client) {
      if (tabSelected.tabNumber === 1) {
        return <TabGeneralData clientData={signatureData.client} />;
      }

      switch (tabSelected.tabStep) {
        case SystemStepCategory.DOCUMENT_UNICO:
          return (
            <>
              {signatureData?.unicoProcess?.document ? (
                <TabDocument signatureData={signatureData} />
              ) : (
                <NoDataStyled>
                  <XCircleSvg />
                  <h1>{t('documents.identity.noData')}</h1>
                  <div>
                    <span>{t('admin.signatures.noCompleted')}</span>
                    <br />
                    <span>{t('admin.signatures.completeWhenUpdate')}</span>
                  </div>
                </NoDataStyled>
              )}
            </>
          );
        case SystemStepCategory.OCR:
          return <TabOCR signatureData={signatureData} organizationData={organizationData} />;

        case SystemStepCategory.FACE_MATCH:
          return (
            <>
              {signatureData?.faceMatch ? (
                <TabFaceMatch signatureData={signatureData} />
              ) : (
                <ContentContainer>
                  <CampName>{t('enums.faceOrOCR.notExecuted')}</CampName>
                </ContentContainer>
              )}
            </>
          );

        case SystemStepCategory.SELFIE:
          return (
            <ContentContainer>
              {signatureData?.liveness?.attachment ? (
                <ContentContainer key={3} onClick={() => setExpandModal(!expandModal)}>
                  <StyledExpandIcon />
                  <CampName style={{ width: 'fit-content' }}>{t('admin.signatures.clientSelfie')}</CampName>
                  <ModalContainer $expanded={expandModal} onClick={() => setExpandModal(!expandModal)} />
                  <SelfieDisplayAssign
                    onClick={() => setExpandModal(!expandModal)}
                    $expanded={expandModal}
                    src={StringUtils.getFileOrUrl(signatureData?.liveness?.attachment as Attachment)}
                  />
                </ContentContainer>
              ) : (
                <CampName>{t('documents.identity.emptyDocs')}</CampName>
              )}
            </ContentContainer>
          );
        case SystemStepCategory.LIVENESS:
          return !signatureData?.unicoProcess?.biometric?.image &&
            !signatureData?.unicoProcess?.processStatus?.statusBiometricUnico &&
            !signatureData?.unicoProcess?.processStatus?.statusBiometricInternal ? (
            <NoDataStyled>
              <XCircleSvg />
              <h1>{t('documents.identity.noData')}</h1>
              <div>
                <span>{t('admin.signatures.noCompleted')}</span>
                <br />
                <span>{t('admin.signatures.completeWhenUpdate')}</span>
              </div>
            </NoDataStyled>
          ) : (
            <LivenessContainer>
              {signatureData?.unicoProcess?.biometric?.image && (
                <ContentContainer key={3} onClick={() => setExpandModal(!expandModal)}>
                  <StyledExpandIcon />
                  <CampName style={{ width: 'fit-content' }}>{t('admin.signatures.signatureSelfie')}</CampName>
                  <ModalContainer $expanded={expandModal} onClick={() => setExpandModal(!expandModal)} />
                  <SelfieDisplayAssign
                    onClick={() => setExpandModal(!expandModal)}
                    $expanded={expandModal}
                    src={StringUtils.getFileOrUrl(signatureData?.unicoProcess?.biometric?.image)}
                  />
                </ContentContainer>
              )}
              {signatureData?.unicoProcess?.processStatus?.codBiometricUnico !== undefined && (
                <ContentContainer>
                  <CampName style={{ width: 'fit-content' }}>{t('admin.signatures.statusBiometric')}</CampName>
                  <>
                    <Status
                      big
                      text={
                        signatureData?.unicoProcess?.processStatus?.codBiometricUnico === 200
                          ? t(`enum.numberStatus.sendWithSuccess`)
                          : signatureData?.unicoProcess?.processStatus?.codBiometricUnico === 50002
                            ? t(`enum.unicoProcessStatus.processingCPF`)
                            : signatureData?.unicoProcess?.processStatus?.codBiometricUnico === 40009
                              ? t(`enum.unicoProcessStatus.suspectScore`)
                              : errorsBiometricArray.includes(signatureData?.unicoProcess?.processStatus?.codBiometricUnico)
                                ? t(`enum.unicoProcessStatus.clientNeTry`)
                                : t(`enum.unicoProcessStatus.errorUnico`)
                      }
                      color={
                        signatureData?.unicoProcess?.processStatus?.codBiometricUnico === 200
                          ? 'DONE'
                          : signatureData?.unicoProcess?.processStatus?.codBiometricUnico === 50002
                            ? 'IN_PROGRESS'
                            : signatureData?.unicoProcess?.processStatus?.codBiometricUnico === 40009
                              ? 'IN_PROGRESS'
                              : errorsBiometricArray.includes(signatureData?.unicoProcess?.processStatus?.codBiometricUnico)
                                ? 'IN_PROGRESS'
                                : 'PROBLEM'
                      }
                    />
                  </>
                </ContentContainer>
              )}
            </LivenessContainer>
          );

        case SystemStepCategory.HISTORIC:
          return <NewTableList loadingTable={isLoadingSignatureHistoryData} tableValues={signatureHistoryTableValues} />;

        case SystemStepCategory.ANALYSIS_UNICO:
          return !signatureData?.unicoProcess?.idUnico ? (
            <NoDataStyled>
              <XCircleSvg />
              <h1>{t('documents.identity.noData')}</h1>
              <div>
                <span>{t('admin.signatures.noCompleted')}</span>
                <br />
                <span>{t('admin.signatures.completeWhenUpdate')}</span>
              </div>
            </NoDataStyled>
          ) : (
            <TabUnico unicoData={signatureData?.unicoProcess} />
          );

        default:
          return <></>;
      }
    }
  };

  const getSignatureData = async (signatureId: number) => {
    await clientService.getSignatureData(signatureId).then(res => {
      setStepStatus([
        {
          stepName: t('admin.signatures.waitingStart'),
          order: 0,
          status:
            res.personSignatureStatus === PersonSignatureStatusTypes.AWAITING_SUBMISSION_FOR_SIGNATURE
              ? GeneralStatus.IN_PROGRESS
              : GeneralStatus.FINISHED,
        },
        {
          stepName: t('admin.signatures.generalData'),
          order: 1,
          status: res?.isConfirmedData ? GeneralStatus.FINISHED : GeneralStatus.IN_PROGRESS,
        },
        {
          stepName: t('admin.signatures.biometric'),
          order: 2,
          status:
            res.personSignatureStatus === PersonSignatureStatusTypes.ERROR_LIVENESS
              ? GeneralStatus.CANCELLED
              : res?.unicoProcess?.processStatus?.codBiometricUnico === 200
                ? GeneralStatus.FINISHED
                : GeneralStatus.IN_PROGRESS,
        },
        {
          stepName: t('admin.signatures.document'),
          order: 3,
          status:
            res.personSignatureStatus === PersonSignatureStatusTypes.ERROR_OCR
              ? GeneralStatus.CANCELLED
              : res?.unicoProcess?.processStatus?.codDocumentUnico === 200
                ? GeneralStatus.FINISHED
                : GeneralStatus.IN_PROGRESS,
        },
        {
          stepName: t('admin.signatures.unicoAnalysis'),
          order: 4,
          status:
            res.personSignatureStatus === PersonSignatureStatusTypes.ERROR_FACEMATCH
              ? GeneralStatus.CANCELLED
              : res?.unicoProcess?.processStatus?.codFinishUnico === 200
                ? GeneralStatus.FINISHED
                : GeneralStatus.IN_PROGRESS,
        },
        {
          stepName: t('admin.signatures.finished'),
          order: 5,
          status:
            res.personSignatureStatus === PersonSignatureStatusTypes.CANCELED
              ? GeneralStatus.CANCELLED
              : res?.processFinished
                ? GeneralStatus.FINISHED
                : GeneralStatus.IN_PROGRESS,
        },
      ]);
      setSignatureData(res);
    });
  };

  const getSignatureHistory = () => {
    clientService.getSignatureHistory(Number(id)).then(reponse => {
      setSignatureHistoryData(reponse);
      setIsLoadingSignatureHistoryData(false);
    });
  };

  const allowNewTry = (message: string) => {
    setIsShowingCanRetryModal(false);
    if (id && !loadingNewRetry) {
      setloadingNewRetry(true);
      clientService
        .enableRetry(Number(id), {
          internalComment: message,
        })
        .then(_ => window.location.reload())
        .catch(error => {
          const errorCode = error?.split('.');
          handleExceededAttemptsModal(errorCode[errorCode.length - 1]);
          setIsShowExceededAttemptsModal(true);
          setloadingNewRetry(false);
        });
    }
  };

  const reesendEmail = () => {
    setEmailButtonLabel(t('admin.signatures.reeesending'));
    if (id) {
      clientService.resendEmail(Number(id)).then(res => setEmailButtonLabel(t('admin.signatures.reeesended')));
    }
  };

  const cancelContract = () => {
    setloadingCancelContract(true);
    if (id) {
      clientService.cancelContract(Number(id)).then(response => window.location.reload());
    }
  };

  const resendConfirmation = () => {
    if (id) {
      clientService.resendConfirmation(Number(id)).then(response => window.location.reload());
    }
  };

  const approveErrorStep = (type: string) => {
    if (id && !loadingApprove) {
      setLoadingApprove(true);
      clientService.manualApproval({ type, personSignatureId: Number(id) }).then(_ => window.location.reload());
    }
  };

  const getOrganizationData = () => {
    organizationService.getCurrentOrganization().then(setOrganizationData);
  };

  const handleExceededAttemptsModal = (errorCode?: string) => {
    if (errorCode === ErrorEnableRetry.OCR) {
      setDataExceededAttemptsModal({ errorType: t('admin.signatures.OCR'), numberAttempts: organizationData?.numberOfDocFraudCalls ?? 0 });
    }

    if (errorCode === ErrorEnableRetry.MATCH) {
      setDataExceededAttemptsModal({
        errorType: t('admin.signatures.faceMatch'),
        numberAttempts: organizationData?.numberOfDocFraudCalls ?? 0,
      });
    }
    if (errorCode === ErrorEnableRetry.LIVENESS) {
      setDataExceededAttemptsModal({
        errorType: t('admin.signatures.liveness'),
        numberAttempts: organizationData?.numberOfDocFraudCalls ?? 0,
      });
    }
  };

  return (
    <>
      <PageContainer>
        <Header variant={HeaderVariants.SECONDARY} />
        <TitleHeader>
          <Title>
            <div className="firstTitle">
              <button
                onClick={() => {
                  if (location.state?.prevPath) {
                    history.push(location.state.prevPath);
                  } else {
                    history.push('/admin/assinaturas');
                  }
                }}
              >
                <FiChevronLeft color={color.primaryColor} />
              </button>
              <span className="title">{t('admin.signatures.subscriptionData')}</span>
            </div>
          </Title>
          {hasAuthorities(account?.authorities, [Authority.ROLE_ADMIN, Authority.ROLE_INTERNAL_ADMIN]) && (
            <ButtonsContainer>
              {(signatureData?.personSignatureStatus === PersonSignatureStatusTypes.AWAITING_SUBMISSION_FOR_SIGNATURE ||
                signatureData?.personSignatureStatus === PersonSignatureStatusTypes.SENT_FOR_SIGNATURE) && (
                <CustomButton onClick={reesendEmail} isInvertColor>
                  {emailButtonLabel}
                </CustomButton>
              )}
              {signatureData &&
                (signatureData?.personSignatureStatus === PersonSignatureStatusTypes.ERROR_OCR ||
                  signatureData?.personSignatureStatus === PersonSignatureStatusTypes.ERROR_FACEMATCH ||
                  signatureData?.personSignatureStatus === PersonSignatureStatusTypes.ERROR_LIVENESS) && (
                  <CustomButton
                    onClick={() => {
                      setIsShowingReplaceDocModal(true);
                    }}
                    isInvertColor
                  >
                    {t('admin.signatures.replaceDoc')}
                  </CustomButton>
                )}
              {!signatureData?.canRetry &&
                signatureData?.personSignatureStatus !== PersonSignatureStatusTypes.DONE &&
                signatureData?.personSignatureStatus !== PersonSignatureStatusTypes.WAITING_SIGNATURE_FINISH && (
                  <CustomButton
                    onClick={() => {
                      setIsShowingCanRetryModal(true);
                    }}
                    isInvertColor
                  >
                    {!loadingNewRetry ? t('admin.signatures.allowNewTry') : t('admin.signatures.allowingNewTry')}
                  </CustomButton>
                )}

              {signatureData?.personSignatureStatus === PersonSignatureStatusTypes.BIOMETRIC_COMPANY_CHECK && (
                <CustomButton
                  onClick={() => {
                    approveErrorStep('BIOMETRIC');
                  }}
                >
                  {!loadingApprove ? t('admin.signatures.approveBiometric') : t('admin.signatures.approving')}
                </CustomButton>
              )}

              {signatureData?.personSignatureStatus === PersonSignatureStatusTypes.DOCUMENT_ADMIN_CHECK && (
                <CustomButton
                  onClick={() => {
                    approveErrorStep('DOCUMENT');
                  }}
                >
                  {!loadingApprove ? t('admin.signatures.approveDocument') : t('admin.signatures.approving')}
                </CustomButton>
              )}
              {signatureData?.personSignatureStatus === PersonSignatureStatusTypes.UNICO_COMPANY_CHECK &&
                (signatureData?.unicoProcess?.statusProcess === UnicoProcessStatus.FRAUD_POSSIBLE ||
                  signatureData?.unicoProcess?.statusProcess === UnicoProcessStatus.FRAUD_NEUTRAL ||
                  signatureData?.unicoProcess?.statusProcess === UnicoProcessStatus.FRAUD_NO_EVIDENCES) && (
                  <CustomButton
                    onClick={() => {
                      approveErrorStep('CHECK_FRAUD_UNICO');
                    }}
                  >
                    {!loadingApprove ? t('admin.signatures.approveFraud') : t('admin.signatures.approving')}
                  </CustomButton>
                )}
              {signatureData?.personSignatureStatus === PersonSignatureStatusTypes.ERROR_LIVENESS && (
                <CustomButton
                  onClick={() => {
                    approveErrorStep('LIVENESS_UNICO');
                  }}
                >
                  {!loadingApprove ? t('admin.signatures.approveProofOfLife') : t('admin.signatures.approving')}
                </CustomButton>
              )}
              {signatureData?.personSignatureStatus === PersonSignatureStatusTypes.ERROR_OCR && (
                <CustomButton
                  onClick={() => {
                    approveErrorStep('OCR_UNICO');
                  }}
                >
                  {!loadingApprove ? t('admin.signatures.approveOCR') : t('admin.signatures.approving')}
                </CustomButton>
              )}
              {signatureData?.personSignatureStatus === PersonSignatureStatusTypes.ERROR_FACEMATCH && (
                <CustomButton
                  onClick={() => {
                    approveErrorStep('FACEMATCH_UNICO');
                  }}
                >
                  {!loadingApprove ? t('admin.signatures.approveFaceMatch') : t('admin.signatures.approving')}
                </CustomButton>
              )}
              {((signatureData?.personSignatureStatus === PersonSignatureStatusTypes.SENT_FOR_SIGNATURE &&
                signatureData?.unicoProcess?.statusProcessUnico === 1 &&
                signatureData?.unicoProcess?.processStatus?.codBiometricUnico === 200 &&
                signatureData?.unicoProcess?.processStatus?.codDocumentUnico === 200 &&
                signatureData?.unicoProcess?.processStatus?.codFinishUnico === 200 &&
                signatureData?.unicoProcess?.processStatus?.statusProcessUnico === 200) ||
                (signatureData?.unicoProcess?.processStatus?.codBiometricUnico === 200 &&
                  signatureData?.unicoProcess?.processStatus?.codDocumentUnico === 200 &&
                  signatureData?.unicoProcess?.processStatus?.codFinishUnico === 200 &&
                  signatureData?.unicoProcess?.statusProcess === UnicoProcessStatus.SYSTEM_ERROR) ||
                (signatureData?.personSignatureStatus === PersonSignatureStatusTypes.UNICO_COMPANY_CHECK &&
                  signatureData?.unicoProcess?.statusProcess === UnicoProcessStatus.UNICO_PROBLEM)) && (
                <CustomButton
                  onClick={() => {
                    approveErrorStep('PROBLEM_UNICO');
                  }}
                >
                  {!loadingApprove ? t('admin.signatures.approveUnico') : t('admin.signatures.approving')}
                </CustomButton>
              )}
              {signatureData?.personSignatureStatus !== PersonSignatureStatusTypes.CANCELED &&
                signatureData?.personSignatureStatus !== PersonSignatureStatusTypes.DONE &&
                signatureData?.personSignatureStatus !== PersonSignatureStatusTypes.WAITING_SIGNATURE_FINISH && (
                  <CancelButton $height={42} $isInvertColor onClick={cancelContract}>
                    {!loadingCancelContract ? t('admin.signatures.abortingSignature') : t('admin.signatures.aborting')}
                  </CancelButton>
                )}
              {signatureData?.personSignatureStatus === PersonSignatureStatusTypes.DONE &&
                signatureData?.contractSignatory?.contractSign?.finishDate !== null && (
                  <CustomButton height={42} isInvertColor onClick={resendConfirmation}>
                    {t('admin.signatures.resendConfirmation')}
                  </CustomButton>
                )}
            </ButtonsContainer>
          )}
        </TitleHeader>
        <StepBarSignatureProgress stepStatus={stepStatus} />
        <InfosContainer>
          <TabHeaderInnerContainer style={{ borderRight: '1px solid #E5E7E7', paddingRight: '16px', marginRight: '16px' }}>
            <TabTitleHeader>{t('admin.signatures.dateSend')}</TabTitleHeader>
            <span>{StringUtils.apiDateFormatMask(signatureData?.contractSignatory?.contractSign?.sendToSignDate) ?? ''}</span>
          </TabHeaderInnerContainer>
          <TabHeaderInnerContainer style={{ borderRight: '1px solid #E5E7E7', paddingRight: '16px', marginRight: '16px' }}>
            <TabTitleHeader>{t('admin.signatures.limitDate')}</TabTitleHeader>
            <span>{StringUtils.apiDateFormatMask(signatureData?.contractSignatory?.contractSign?.deadline) ?? ''}</span>
          </TabHeaderInnerContainer>
          <TabHeaderInnerContainer
            style={{ minWidth: '150px', borderRight: '1px solid #E5E7E7', paddingRight: '16px', marginRight: '16px' }}
          >
            <TabTitleHeader>{t('admin.signatures.statusOfSignature')}</TabTitleHeader>
            <Status
              text={
                signatureData?.personSignatureStatus
                  ? t(`enum.signatureStatus.${signatureData.personSignatureStatus}`)
                  : t(`enum.signatureStatus.AWAITING_SUBMISSION_FOR_SIGNATURE`)
              }
              color={signatureData?.personSignatureStatus ?? PersonSignatureStatusTypes.AWAITING_SUBMISSION_FOR_SIGNATURE}
            />
          </TabHeaderInnerContainer>
          <TabHeaderInnerContainer
            style={{ minWidth: '150px', borderRight: '1px solid #E5E7E7', paddingRight: '16px', marginRight: '16px' }}
          >
            <TabTitleHeader>{t('admin.signatures.newRetry')}</TabTitleHeader>
            <Status
              text={signatureData?.canRetry ? t(`enum.signatureStatus.enabled`) : t(`enum.signatureStatus.notEnabled`)}
              color={signatureData?.canRetry ? PersonSignatureStatusTypes.SENT_FOR_SIGNATURE : PersonSignatureStatusTypes.CANCELED}
            />
          </TabHeaderInnerContainer>
          <TabHeaderInnerContainer style={{ borderRight: '1px solid #E5E7E7', paddingRight: '16px', marginRight: '16px', flex: '1' }}>
            {signatureData?.internalComment && (
              <InfoButton
                onClick={() => {
                  setIsShowingMessageModal(true);
                }}
              >
                <span>{t('admin.signatures.seeMessageToCLient')}</span>
                <FiChevronRight color={color.primaryColor} />
              </InfoButton>
            )}
          </TabHeaderInnerContainer>
          <TabHeaderInnerContainer style={{ paddingRight: '16px', marginRight: '16px' }}>
            <Link to={`/admin/contratos/analise/${signatureData?.contractSignatory?.contractSign?.contract?.id}`}>
              <InfoButton>
                <span>{t('admin.signatures.seeContract')}</span>
                <FiChevronRight color={color.primaryColor} />
              </InfoButton>
            </Link>
          </TabHeaderInnerContainer>
        </InfosContainer>
        <TabContainer>
          <TabBox>
            <Tab $isSelected={tabSelected.tabNumber === 1} onClick={() => setTabSelected({ tabNumber: 1 })}>
              <span>{t('admin.signatures.generalData')}</span>
            </Tab>
            <Tab
              $isSelected={tabSelected.tabStep === SystemStepCategory.DOCUMENT_UNICO}
              onClick={() => setTabSelected({ tabNumber: 2, tabStep: SystemStepCategory.DOCUMENT_UNICO })}
            >
              <span>{t('admin.signatures.documents')}</span>
            </Tab>
            <Tab
              $isSelected={tabSelected.tabStep === SystemStepCategory.LIVENESS}
              onClick={() => setTabSelected({ tabNumber: 6, tabStep: SystemStepCategory.LIVENESS })}
            >
              <span>{t('admin.signatures.biometric')}</span>
            </Tab>

            <Tab
              $isSelected={tabSelected.tabStep === SystemStepCategory.ANALYSIS_UNICO}
              onClick={() => setTabSelected({ tabNumber: 7, tabStep: SystemStepCategory.ANALYSIS_UNICO })}
            >
              <span>{t('admin.signatures.tabHistoric.tabAnalysisUnico')}</span>
            </Tab>
          </TabBox>
          <TabContent>{getTabContent()}</TabContent>
        </TabContainer>
      </PageContainer>
      {signatureData?.internalComment && (
        <MessageModal
          message={signatureData.internalComment}
          isShowModal={isShowingMessageModal}
          handleClose={() => {
            setIsShowingMessageModal(false);
          }}
        />
      )}
      <CanRetryModal
        isShowModal={isShowingCanRetryModal}
        handleClick={allowNewTry}
        handleClose={() => {
          setIsShowingCanRetryModal(false);
        }}
      />
      {signatureData && (
        <ReplaceDocModal
          signatureUUID={signatureData.uuid}
          isShowModal={isShowingReplaceDocModal}
          handleClose={() => {
            setIsShowingReplaceDocModal(false);
          }}
        />
      )}
      <CustomModalTwoButtons
        isShowModal={isShowExceededAttemptsModal}
        onCloseModal={() => setIsShowExceededAttemptsModal(false)}
        icon={<RedExclamationCircleSvg />}
        title={t('admin.signatures.unableRetry')}
        children={
          <StyleTextModalContainer>
            {t('admin.signatures.maximumQuantity', {
              type: dataExceededAttemptsModal?.errorType,
              value: dataExceededAttemptsModal?.numberAttempts,
            })}
          </StyleTextModalContainer>
        }
        secondButtonText={t('global.button.ok')}
        handleClickSecondButton={() => setIsShowExceededAttemptsModal(false)}
      />
    </>
  );
};
