export interface ResendAttachment {
  documents: AttachmentType[];
  type: 'DRIVERS_LICENSE' | 'DEFAULT' | 'FEDERAL_ID';
}

export interface AttachmentType {
  type: UnicoDocumentType;
  file: string[] | string;
  fileType?: string;
}

export enum UnicoDocumentType {
  FRONT = 'FRONT',
  BACK = 'BACK',
}
