import CustomModalTwoButtons from 'components/custom-modal-two-buttons';
import { PopupContent } from 'components/simulator-data/aproved/style';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  ContainerTitleAndData,
  DragAndDrop,
  FileContainer,
  LabelComponent,
  PopupText,
  PopupTitle,
  RadiusInput,
  RadiusLabel,
  RadiusOptionWrapper,
  SpanSubTitle,
  SpanTitle,
  Upload,
} from './styles';
import { useEffect, useState } from 'react';
import { AttachmentType } from 'model/enums/attachment-types';
import StringUtils from 'shared/util/string-utils';
import { Attachment } from 'model/attachment';
import NewSimpleContainerDocument from 'components/new-container-document';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import * as yup from 'yup';
import { set, truncate } from 'lodash';
import PaperclipIcon from 'images/paperclip.svg';
import CustomButton from 'components/custom-button';
import clientService from 'services/client-service';
import { UnicoDocumentType } from 'model/resend-attachment';

interface Props extends WithTranslation {
  signatureUUID: string;
  isShowModal: boolean;
  handleClose: () => void;
}

const ReplaceDocModal = ({ signatureUUID, isShowModal, handleClose, t }: Props) => {
  const [radius, setRadius] = useState<'DRIVERS_LICENSE' | 'DEFAULT' | 'FEDERAL_ID'>();
  const [files, setFiles] = useState<Attachment[]>([]);
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const schema = yup.object({
    attachmentIdentification: yup.mixed(),
  });

  const handleRadiusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadius(event.target.value as 'DRIVERS_LICENSE' | 'DEFAULT' | 'FEDERAL_ID');
  };

  const handleSubmit = () => {
    if (radius && files.length > 0) {
      setLoading(true);
      clientService
        .reSendDocs(signatureUUID, {
          documents: files.map(item => {
            let type = item.type === 'rgVerse' || item.type === 'otherVerse' ? UnicoDocumentType.BACK : UnicoDocumentType.FRONT;
            return {
              type,
              file: item.file as string,
              fileType: item.contentType,
            };
          }),
          type: radius,
        })
        .then(res => {
          setLoading(false);
          location.reload();
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (
      (radius === 'DRIVERS_LICENSE' && files.filter(item => item.type === 'DRIVERS_LICENSE').length > 0) ||
      (radius === 'FEDERAL_ID' &&
        files.filter(item => item.type === 'rgFront').length > 0 &&
        files.filter(item => item.type === 'rgVerse').length > 0) ||
      (radius === 'DEFAULT' &&
        files.filter(item => item.type === 'otherFront').length > 0 &&
        files.filter(item => item.type === 'otherVerse').length > 0)
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [files, radius]);

  function getBase64FileSize(base64String) {
    const padding = (base64String.match(/=+$/) || [''])[0].length;
    const base64Length = base64String.length - padding;
    const fileSizeInBytes = (base64Length * 3) / 4;

    const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
    return fileSizeInMB.toFixed(2);
  }

  const handleRemoveFile = (fileName: string) => {
    const filesFiltred = files.filter(file => file.fileName !== fileName);
    setFiles(filesFiltred);
  };

  useEffect(() => {
    setFiles([]);
  }, [radius]);

  return (
    <CustomModalTwoButtons
      isShowModal={isShowModal}
      onCloseModal={() => handleClose()}
      large
      children={
        <PopupContent>
          <ReactHookFormProvider schema={schema} onSubmit={handleSubmit}>
            <PopupTitle>{t('admin.signatures.replaceDoc')}</PopupTitle>
            <PopupText>{t('admin.signatures.selectDoc')}</PopupText>

            <RadiusOptionWrapper>
              <RadiusLabel $isActive={radius === 'DRIVERS_LICENSE'}>
                <RadiusInput
                  type="radio"
                  name="radius"
                  value="DRIVERS_LICENSE"
                  onChange={handleRadiusChange}
                  checked={radius === 'DRIVERS_LICENSE'}
                />
                <LabelComponent>
                  <SpanTitle>{t('contract.registerDocument.selectDocument.cnh')}</SpanTitle>
                  <SpanSubTitle>{t('contract.registerDocument.selectDocument.nationalIdentityCard')}</SpanSubTitle>
                </LabelComponent>
              </RadiusLabel>

              {radius === 'DRIVERS_LICENSE' && files.filter(item => item.type === 'DRIVERS_LICENSE').length < 1 && (
                <NewSimpleContainerDocument
                  titleExplanation={t('signatures.cnhExplanation')}
                  editDocument
                  nameInput="doc1"
                  name="attachmentIdentification"
                  type={'DRIVERS_LICENSE'}
                  files={files}
                  setFiles={setFiles}
                  title={files && files.find(att => att.type === AttachmentType.IDENTIFICATION_CARD)?.fileName}
                  docUrl={StringUtils.getFileOrUrl(files.find(att => att.type === AttachmentType.IDENTIFICATION_CARD))}
                />
              )}
              {radius === 'DRIVERS_LICENSE' &&
                files
                  .filter(item => item.type === 'DRIVERS_LICENSE')
                  .map((file, key) => (
                    <FileContainer key={key}>
                      <DragAndDrop>
                        <Upload>
                          <img src={PaperclipIcon} />
                        </Upload>
                        <ContainerTitleAndData>
                          <a className="title" download={file.fileName} href={file.url} target="_blank">
                            {file.fileName && truncate(file.fileName, { length: 25 })}
                          </a>
                          <span className="subtitle">{`${getBase64FileSize(file.file)} MB`}</span>
                        </ContainerTitleAndData>
                      </DragAndDrop>
                      <CustomButton
                        style={{ justifyContent: 'center', borderRadius: '8px' }}
                        height={48}
                        width="190px"
                        isInvertColor
                        onClick={() => {
                          file.fileName && handleRemoveFile(file?.fileName);
                        }}
                      >
                        {t('admin.signatures.chnageFile')}
                      </CustomButton>
                    </FileContainer>
                  ))}

              <RadiusLabel $isActive={radius === 'FEDERAL_ID'}>
                <RadiusInput
                  type="radio"
                  name="radius"
                  value="FEDERAL_ID"
                  onChange={handleRadiusChange}
                  checked={radius === 'FEDERAL_ID'}
                />
                <LabelComponent>
                  <SpanTitle> {t('contract.registerDocument.selectDocument.rg')}</SpanTitle>
                  <SpanSubTitle> {t('contract.registerDocument.selectDocument.nationalIdentityCard')}</SpanSubTitle>
                </LabelComponent>
              </RadiusLabel>
              {radius === 'FEDERAL_ID' && (
                <>
                  {files.filter(item => item.type === 'rgFront').length < 1 && (
                    <NewSimpleContainerDocument
                      titleExplanation={t('signatures.rgFrontExplanation')}
                      editDocument
                      nameInput="doc1"
                      name="attachmentIdentification"
                      type={'rgFront'}
                      files={files}
                      setFiles={setFiles}
                      title={files && files.find(att => att.type === AttachmentType.IDENTIFICATION_CARD)?.fileName}
                      docUrl={StringUtils.getFileOrUrl(files.find(att => att.type === AttachmentType.IDENTIFICATION_CARD))}
                    />
                  )}
                  {files.filter(item => item.type === 'rgVerse').length < 1 && (
                    <NewSimpleContainerDocument
                      titleExplanation={t('signatures.rgVerseExplanation')}
                      editDocument
                      nameInput="doc1"
                      name="attachmentIdentification"
                      type={'rgVerse'}
                      files={files}
                      setFiles={setFiles}
                      title={files && files.find(att => att.type === AttachmentType.IDENTIFICATION_CARD)?.fileName}
                      docUrl={StringUtils.getFileOrUrl(files.find(att => att.type === AttachmentType.IDENTIFICATION_CARD))}
                    />
                  )}
                </>
              )}
              {radius === 'FEDERAL_ID' &&
                files
                  .filter(item => item.type === 'rgFront' || item.type === 'rgVerse')
                  .map((file, key) => (
                    <FileContainer key={key}>
                      <DragAndDrop>
                        <Upload>
                          <img src={PaperclipIcon} />
                        </Upload>
                        <ContainerTitleAndData>
                          <a className="title" download={file.fileName} href={file.url} target="_blank">
                            {file.fileName && truncate(file.fileName, { length: 25 })}
                          </a>
                          <span className="subtitle">{`${getBase64FileSize(file.file)} MB`}</span>
                        </ContainerTitleAndData>
                      </DragAndDrop>
                      <CustomButton
                        style={{ justifyContent: 'center', borderRadius: '8px' }}
                        height={48}
                        width="190px"
                        isInvertColor
                        onClick={() => {
                          file.fileName && handleRemoveFile(file?.fileName);
                        }}
                      >
                        {t('admin.signatures.chnageFile')}
                      </CustomButton>
                    </FileContainer>
                  ))}

              <RadiusLabel $isActive={radius === 'DEFAULT'}>
                <RadiusInput type="radio" name="radius" value="DEFAULT" onChange={handleRadiusChange} checked={radius === 'DEFAULT'} />
                <LabelComponent>
                  <SpanTitle>{t('contract.registerDocument.selectDocument.other')}</SpanTitle>
                  <SpanSubTitle>{t('contract.registerDocument.selectDocument.otherDocumentTypes')}</SpanSubTitle>
                </LabelComponent>
              </RadiusLabel>
              {radius === 'DEFAULT' && (
                <>
                  {files.filter(item => item.type === 'otherFront').length < 1 && (
                    <NewSimpleContainerDocument
                      titleExplanation={t('signatures.otherFrontExplanation')}
                      editDocument
                      nameInput="doc1"
                      name="attachmentIdentification"
                      type={'otherFront'}
                      files={files}
                      setFiles={setFiles}
                      title={files && files.find(att => att.type === AttachmentType.IDENTIFICATION_CARD)?.fileName}
                      docUrl={StringUtils.getFileOrUrl(files.find(att => att.type === AttachmentType.IDENTIFICATION_CARD))}
                    />
                  )}
                  {files.filter(item => item.type === 'otherVerse').length < 1 && (
                    <NewSimpleContainerDocument
                      titleExplanation={t('signatures.otherVerseExplanation')}
                      editDocument
                      nameInput="doc1"
                      name="attachmentIdentification"
                      type={'otherVerse'}
                      files={files}
                      setFiles={setFiles}
                      title={files && files.find(att => att.type === AttachmentType.IDENTIFICATION_CARD)?.fileName}
                      docUrl={StringUtils.getFileOrUrl(files.find(att => att.type === AttachmentType.IDENTIFICATION_CARD))}
                    />
                  )}
                </>
              )}
              {radius === 'DEFAULT' &&
                files
                  .filter(item => item.type === 'otherFront' || item.type === 'otherVerse')
                  .map((file, key) => (
                    <FileContainer key={key}>
                      <DragAndDrop>
                        <Upload>
                          <img src={PaperclipIcon} />
                        </Upload>
                        <ContainerTitleAndData>
                          <a className="title" download={file.fileName} href={file.url} target="_blank">
                            {file.fileName && truncate(file.fileName, { length: 25 })}
                          </a>
                          <span className="subtitle">{`${getBase64FileSize(file.file)} MB`}</span>
                        </ContainerTitleAndData>
                      </DragAndDrop>
                      <CustomButton
                        style={{ justifyContent: 'center', borderRadius: '8px' }}
                        height={48}
                        width="190px"
                        isInvertColor
                        onClick={() => {
                          file.fileName && handleRemoveFile(file?.fileName);
                        }}
                      >
                        {t('admin.signatures.chnageFile')}
                      </CustomButton>
                    </FileContainer>
                  ))}
            </RadiusOptionWrapper>
          </ReactHookFormProvider>
        </PopupContent>
      }
      firstButtonText={t('global.button.cancel')}
      secondButtonText={loading ? t('global.button.sending') : t('global.button.send')}
      handleClickFirstButton={() => {
        handleClose();
      }}
      handleClickSecondButton={() => {
        handleSubmit();
      }}
      disableSecondaryButton={disableButton || loading}
    />
  );
};

export default withTranslation()(ReplaceDocModal);
